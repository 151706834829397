<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          <v-icon large color="red" v-if="type == 'error'">mdi-alert</v-icon>
          <v-icon large color="green" v-else>mdi-check</v-icon>
          {{ title }}
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  color="black darken-1"  text @click="finish(true)"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
export default {
  name: "AlertDialog",
  props: {
    type: { type: String },
    title: { type: String },
    message: { type: String },
    callback: { type: Function },
  },
  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    finish(result) {
      this.dialog = false;
      if (this.callback) {
        this.callback(result);
      }
    },
  },
};
</script>