<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="1" sm="12">
          <v-text-field
            v-model="username"
            :rules="[rules.required, rules.min]"
            :type="'text'"
            label="Uživatelské jméno"
          ></v-text-field>
        </v-col>
        <v-col cols="1" sm="12">
          <v-text-field
            v-model="password"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="showPass ? 'text' : 'password'"
            :color="'white'"
            name="login-pass"
            label="Vložte heslo"
            hint="Alespoň 5 znaků"
            counter
            @click:append="showPass = !showPass"
          ></v-text-field>
        </v-col>
        <v-col cols="1" sm="12">
          <v-btn small width="100%" @click="loginProcess">Přihlásit</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>



<script>
export default {
  name: "Login",
  data() {
    return {
      showPass: false,
      password: "",
      username: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 5 || "Min 5 characters",
      },
    };
  },
  methods: {
    loginProcess() {
      this.$emit("login", this.username, this.password );
    }
  },
};
</script>
