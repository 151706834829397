<template>
  <v-main>
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          value=""
          v-model="programItem.title"
          :rules="rules"
          counter="35"
          hint="Krátký název pořadu. Bude se zobrazovat jako titul v seznamech"
          label="Titul pořadu"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="8">
        <v-text-field
          value=""
          v-model="programItem.subtitle"
          :rules="wordsRules"
          counter="25"
          hint="Stručný popis programu, například se jmény hlavních aktérů, režií atd.."
          label="Krátký popis programu"
          :counter-value="(v) => v.trim().split(' ').length"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="9">
        <v-textarea
          v-model="programItem.desc"
          outlined
          name="input-7-4"
          label="Kompletní popis programu"
          :value="programItem.desc"
        ></v-textarea>
      </v-col>
      <v-col class="d-flex" cols="12" sm="3">
        <v-select
          :items="langCodes"
          v-model="programItem.lang"
          :rules="[(v) => !!v || 'Hodnota musí být vyplněna']"
          item-text="name"
          item-value="value"
          label="Jazyková verze"
          :value="programItem.lang"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2"><v-btn @click="saveProgram">Uložit</v-btn></v-col>
      <v-col cols="12" md="2">
        <v-text-field
          dense
          v-model.number="program.duration"
          type="number"
          hint="Délka programu v minutách"
          label="Duration"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="8" align="right"
        ><v-btn @click="addTags">Přidat jazyk</v-btn></v-col
      >
    </v-row>
    <v-row v-for="(tag, index) in this.program.tag" :key="index">
      <v-col cols="12" sm="9">
        <v-textarea
          v-model="tag.content"
          outlined
          rows="2"
          name="input-7-4"
          :label="tag.tagName"
          :value="tag.content"
        ></v-textarea>
      </v-col>
      <v-col class="d-flex" cols="12" sm="3">
        <v-select
          v-model="tag.lang"
          :items="langCodes"
          :rules="[(v) => !!v || 'Hodnota musí být vyplněna']"
          item-text="name"
          item-value="value"
          label="Jazyková verze"
          :value="tag.lang"
        ></v-select>
        <v-icon medium right @click.passive="deleteTag(tag.id)">
          mdi-trash-can-outline
        </v-icon>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
export default {
  name: "ProgramEdit",
  props: {
    program: Object,
  },
  data() {
    return {
      programItem: { lang: "cz", title: "", subtitle: "", desc: "" },
      langCodes: [
        { name: "čeština", value: "cz" },
        { name: "slovenština", value: "sk" },
        { name: "angličtina", value: "en" },
      ],
      title: "",
      rules: [(v) => v.length <= 35 || "Max 35 characters"],
      wordsRules: [(v) => v.trim().split(" ").length <= 25 || "Max 5 words"],
    };
  },
  computed: {
    programParse() {      
      const programSrc = {};
      for (const i in this.program.tag) {
        if (!programSrc[this.program.tag[i].lang]) {
          programSrc[this.program.tag[i].lang] = {};
          programSrc[this.program.tag[i].lang]["lang"] = this.program.tag[
            i
          ].lang;
          programSrc[this.program.tag[i].lang]["title"] = "";
          programSrc[this.program.tag[i].lang]["subtitle"] = "";
          programSrc[this.program.tag[i].lang]["desc"] = "";
          
        }
        if (this.program.tag[i].tagName == "title") {
          programSrc[this.program.tag[i].lang]["title"] = this.program.tag[
            i
          ].content;
        }
        if (this.program.tag[i].tagName == "sub-title") {
          programSrc[this.program.tag[i].lang]["subtitle"] = this.program.tag[
            i
          ].content;
        }
        if (this.program.tag[i].tagName == "desc") {
          programSrc[this.program.tag[i].lang]["desc"] = this.program.tag[
            i
          ].content;
        }
      }
      return programSrc;
    },
  },
  methods: {
    deleteTag(id) {
      this.$toast(
        "Byl odebrán jeden popis programu. Aby se operace projevila, je třeba změny uložit."
      );
      for (const i in this.program.tag) {
        if (this.program.tag[i].id == id) {
          this.program.tag.splice(i, 1);
        }
      }
    },
    addTags() {
      this.program.tag.push({
        id: 0,
        programmeId: this.program.id,
        tagName: "title",
        lang: this.programItem.lang,
        content: this.programItem.title,
      });
      this.program.tag.push({
        id: 0,
        programmeId: this.program.id,
        tagName: "sub-title",
        lang: this.programItem.lang,
        content: this.programItem.subtitle,
      });
      this.program.tag.push({
        id: 0,
        programmeId: this.program.id,
        tagName: "desc",
        lang: this.programItem.lang,
        content: this.programItem.desc,
      });
      this.$toast(
        "Byl přidán popis programu. Aby se operace projevila, je třeba změny uložit."
      );
    },
    dropLang(key) {
      for (const i in this.program.tag) {
        if (this.program.tag[i].lang == key) {
          this.program.tag.splice(i, 1);
        }
      }
    },
    saveProgram() {       
      this.$emit("saved", this.program);
    },
  },
};
</script>