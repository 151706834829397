<template>
  <v-app>
    <v-app-bar app color="Navy" dark> </v-app-bar>
    <v-main>
      <header>Šlágr XMLTV Editor</header>

      <section>
        <nav>
          <Login v-if="token == null" @login="loginCheck" />
          <MenuLeft @clicked="linkChange" v-if="token != null" />
        </nav>
        <article>
          <program-list v-if="menuType == 1" />
          <program-record-list v-if="menuType == 2" />
        </article>
      </section>

      <footer>
        <p>...::: :::...</p>
      </footer>
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import Login from "./components/Login.vue";
import MenuLeft from "./components/MenuLeft.vue";
import ProgramList from "./components/ProgramList.vue";
import ProgramRecordList from "./components/ProgramRecordList.vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: false,
  timeout: 15000,
});

Date.prototype.toIsoString = function () {
  const tzo = -this.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num) {
      const norm = Math.floor(Math.abs(num));
      return (norm < 10 ? "0" : "") + norm;
    };
  return (
    this.getFullYear() +
    "-" +
    pad(this.getMonth() + 1) +
    "-" +
    pad(this.getDate()) +
    "T" +
    pad(this.getHours()) +
    ":" +
    pad(this.getMinutes()) +
    ":" +
    pad(this.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ":" +
    pad(tzo % 60)
  );
};

Date.prototype.toLocalSpecString = function () {
  const pad = function (num) {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? "0" : "") + norm;
  };
  return (
    this.getUTCFullYear() +
    "-" +
    pad(this.getUTCMonth() + 1) +
    "-" +
    pad(this.getUTCDate()) +
    " " +
    pad(this.getUTCHours()) +
    ":" +
    pad(this.getUTCMinutes()) +
    ":" +
    pad(this.getUTCSeconds())
  );
};

Date.prototype.toLocalDateString = function () {
  const pad = function (num) {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? "0" : "") + norm;
  };
  return (
    this.getFullYear() +
    "-" +
    pad(this.getMonth() + 1) +
    "-" +
    pad(this.getDate())
  );
};

Date.prototype.toLocalTimeString = function () {
  const pad = function (num) {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? "0" : "") + norm;
  };
  return (
    pad(this.getHours()) +
    ":" +
    pad(this.getMinutes()) +
    ":" +
    pad(this.getSeconds())
  );
};

export default Vue.extend({
  name: "App",

  components: {
    Login,
    MenuLeft,
    ProgramList,
    ProgramRecordList,
  },

  data() {
    return {
      token: null,
      menuType: 0,
    };
  },
  methods: {
    linkChange(val) {
      this.menuType = val;
    },
    loginCheck(username, password) {
      console.log(username);
      console.log(password);
      if ((username == "Slagr") && (password == "MusicTv")) {
        this.token = "usertoken";
      } else {
        this.$toast.error("Chybné přihlašovací údaje");
      }
    },
  },
});
</script>

<style scoped>
header {
  background-color: #666;
  padding: 3px;
  text-align: center;
  font-size: 20px;
  color: white;
  height: 50px;
}

header:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

/* Create two columns/boxes that floats next to each other */
nav {
  float: left;
  text-align: center;
  width: 20%;
  height: 80vh;
  background-color: rgb(216, 216, 216);
  padding: 20px;
  display: inline;
  vertical-align: middle;
  overflow: auto;
}

.middcent {
  text-align: center;
  display: block;
  vertical-align: middle;
  overflow: auto;
}

/* Style the list inside the menu */
nav ul {
  list-style-type: none;
  padding: 0;
}

article {
  float: left;
  padding: 20px;
  width: 80%;
  background-color: #f1f1f1;
  height: 80vh;
  overflow: auto;
}

/* Clear floats after the columns */
section:after {
  content: "";
  display: table;
  clear: both;
}

/* Style the footer */
footer {
  background-color: #777;
  padding: 1px;
  text-align: center;
  color: white;
}
</style>
