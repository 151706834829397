<template>
  <v-main>
    <confirm-dialog
      v-if="dialogShow"
      :title="dialogTitle"
      :message="dialogMessage"
      :callback="dialogCallback"
    ></confirm-dialog>
    <alert-dialog
      v-if="dialog"
      :title="dialogTitle"
      :message="dialogMessage"
      :type="dialogMode"
      :callback="hideDialog"
    ></alert-dialog>
    <v-form ref="form">
      <v-row>
        <v-col cols="12" sm="3" md="3">
          <v-menu transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                label="Zadejte datum"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="rules"
              ></v-text-field>
            </template>
            <v-date-picker
              @change="setDay"
              v-model="fromDate"
              no-title
              scrollable
              locale="cs-cz"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="2" md="2">
          <v-select
            @change="setDay"
            v-model="channel"
            :items="channels"
            :rules="rules"
            item-text="name"
            item-value="value"
            label="Výběr kanálu"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="3" md="3">
          <v-menu
            v-if="clonePrepare"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="deteToClone"
                label="Klonovat na:"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="rules"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="deteToClone"
              no-title
              scrollable
              locale="cs-cz"
              :min="fromDate"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn
            small
            color="primary"
            width="100%"
            v-if="fromDate"
            @click="cloneDay"
            >Klonovat den</v-btn
          >
          <v-btn
            small
            color="red"
            width="100%"
            v-if="fromDate"
            @click="removeDay"
            >Smazat den</v-btn
          >
        </v-col>
        <v-col cols="12" sm="2" md="2"></v-col>
        <v-col cols="10" sm="2"> Od: </v-col>
        <v-col cols="10" sm="2"> Do: </v-col>
        <v-col cols="10" sm="4"> Program: </v-col>
      </v-row>

      <v-row>
        <v-col v-if="!overMidnight" cols="10" sm="2">
          <datetime type="time" v-model="timeStart"></datetime>
        </v-col>
        <v-col v-if="overMidnight" cols="10" sm="2">
          <datetime type="datetime" v-model="timeStart"></datetime>
        </v-col>
        <v-col cols="10" sm="2">
          <v-row>
            <v-col v-if="!overMidnight" cols="10" sm="9">
              <datetime type="time" v-model="timeStop"></datetime>
            </v-col>
            <v-col v-if="overMidnight" cols="10" sm="9">
              <datetime type="datetime" v-model="timeStop"></datetime>
            </v-col>
            <v-col cols="10" sm="1"
              ><v-icon @click="overMidnight = !overMidnight"
                >mdi-weather-night</v-icon
              ></v-col
            >
          </v-row>
        </v-col>
        <v-col cols="10" sm="4">
          <v-autocomplete
            ref="filterInput"
            dense
            v-model="programId"
            @change="setProgramOdDo"
            :items="programList"
            @keyup="programFilter"
            item-text="name"
            item-value="value"
            label="Hledat program"
            :rules="rules"
            :search-input="FinderInput"
          ></v-autocomplete>
        </v-col>
        <v-col cols="10" sm="2">
          <v-btn @click="addProgramRecord">Přidat pořad</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <p v-if="programRecordList.length > 0">
      Přehled programů pro den {{ fromDate }}
    </p>

    <day-program-bar :programItems="programBar" />

    <v-banner
      icon="mdi-trash-can-outline"
      v-for="(recordItem, index) in programRecordList"
      :key="index"
      single-line
      @click:icon="removeProgram(recordItem.programmeItem.id)"
    >
      <v-row>
        <v-col cols="10" md="3" lg="1">
          {{ new Date(recordItem.programmeItem.start).toLocalTimeString() }}
        </v-col>
        <v-col cols="10" md="5" lg="3">
          {{ new Date(recordItem.programmeItem.stop).toLocalTimeString() }}
        </v-col>
        <v-col cols="10" md="8" lg="4">
          {{ getTitleFromRecord(recordItem.programme) }}
        </v-col>
      </v-row>
    </v-banner>
  </v-main>
</template>

<script>
import RestApiAdater from "../module/restapi.ts";
import AlertDialog from "./AlertDialog.vue";
import ConfirmDialog from "./ConfirmDialog.vue";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import DayProgramBar from "./dayProgramBar.vue";

const restAdapter = new RestApiAdater();

export default {
  components: {
    AlertDialog,
    ConfirmDialog,
    Datetime,
    DayProgramBar,
  },
  created() {
    this.channel = "slagr-3";
  },
  data() {
    return {
      clonePrepare: false,
      deteToClone: null,
      FinderInput: "",
      dialog: false,
      dialogAction: "none",
      dialogParams: {},
      dialogShow: false,
      dialogTitle: "Title",
      dialogMessage: "Přeji hezký den",
      dialogCallback: this.dialogHandler,
      fromDate: null,
      timeStart: "00:00",
      timeStop: "00:00",
      overMidnight: false,
      programId: null,
      programList: [{ name: "Žádný záznam", value: 0 }],
      programRecordList: [],
      programBar: [],
      channel: "",
      channels: [
        { name: "Šlágr TV 3", value: "slagr-3" },
        { name: "Šlágr Supermix", value: "slagr_mix" },
      ],
      rules: [(v) => !!v || "Hodnota musí být vyplněna"],
    };
  },
  methods: {
    cloneDay() {
      if (this.clonePrepare && this.deteToClone) {
        this.dialogShow = true;
        this.dialogTitle = "Klonování programu";
        this.dialogMessage =
          "Opravdu chcete klonovat záznamy? Původní záznamy na den " +
          this.deteToClone +
          " budou odstraněny";
        this.dialogAction = "clone-program";
      }
      this.clonePrepare = true;
    },
    removeDay() {
      this.dialogShow = true;
      this.dialogTitle = "Odstranění programu";
      this.dialogMessage =
        "Opravdu chcete odstranit všechny záznamy pro den " + this.fromDate;
      this.dialogAction = "remove-program-list";
    },
    setProgramOdDo() {
      try {
        for (const i in this.programList) {
          if (this.programList[i].value == this.programId) {
            this.timeStop = new Date(
              Date.parse(this.timeStart) + this.programList[i].duration * 60000
            ).toISOString();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async dialogHandler(result) {
      this.dialogShow = false;

      if (result) {
        if (this.dialogAction == "delete-program-record") {
          restAdapter.deleteProgramRecord(this.dialogParams.id);
          this.getProgramRecords();
        }
        if (this.dialogAction == "remove-program-list") {
          if (result) {
            const from = this.fromDate;
            const to = new Date(Date.parse(this.fromDate) + 86000000)
              .toIsoString()
              .substring(0, 10);
            try {
              await restAdapter.removeProgramRecord(
                from + " 00:00:00",
                to + " 00:00:00",
                this.channel
              );
              this.setDay();
            } catch (error) {
              console.log(error);
              this.$toast.error("Odstranění programů se nezdařilo!");
            }
          }
        }
        if (this.dialogAction == "clone-program") {
          if (result) {
            const from = this.fromDate;
            const to = new Date(Date.parse(this.fromDate) + 86000000)
              .toIsoString()
              .substring(0, 10);
            try {
              await restAdapter.cloneProgram(
                from + " 00:00:00",
                to + " 00:00:00",
                this.deteToClone + " 00:00:00",
                this.channel
              );
              this.fromDate = this.deteToClone;
              this.setDay();
            } catch (error) {
              console.log(error);
              this.$toast.error("Klonování se nezdařilo!");
            }
          }
        }
      }

      this.dialogAction = "none";
      this.dialogParams = {};
      this.dialogTitle = "";
      this.dialogMessage = "";
    },
    removeProgram(id) {
      this.dialogCallback = this.dialogHandler;
      this.dialogAction = "delete-program-record";
      this.dialogParams = { id: id };
      this.dialogTitle = "Záznam o programu";
      this.dialogMessage = "Opravdu chcete odstranit záznam?";
      this.dialogShow = true;
    },
    setDay() {
      this.$refs.form.validate();
      this.timeStart = new Date(this.fromDate + " 00:00").toISOString();
      this.timeStop = this.timeStart;
      this.deteToClone = this.fromDate;
      this.getProgramRecords();
    },
    async addProgramRecord() {
      const dateString = new Date(this.fromDate).toLocalDateString();
      if (this.$refs.form.validate()) {
        if (new Date(this.timeStart) >= new Date(this.timeStop)) {
          this.showDialog(
            "Časový problém",
            "Program nemůže být zahájen později než je jeho ukončení.\n",
            "error"
          );
          return;
        }

        const recordPrepare = {
          ProgrammeId: this.programId,
          start: this.timeStart,
          stop: this.timeStop,
          channel: this.channel,
        };
        try {
          const result = await restAdapter.addProgramRecord(recordPrepare);
          this.$toast.success("Byl zaznamenán nový pořad do programu");
        } catch (error) {
          this.showDialog(
            "Zápis programu",
            "Bohužel při zápisu nového zánznamu došlo k chybě.\n" +
              (error ? error : "")
          );
        }
      }
      this.getProgramRecords();
    },
    async getProgramRecords() {
      if (!this.fromDate) {
        return;
      }
      const from = this.fromDate;
      const to = new Date(Date.parse(this.fromDate) + 86000000)
        .toIsoString()
        .substring(0, 10);

      try {
        this.programRecordList = await restAdapter.getProgramRecord(
          from + " 00:00:00",
          to + " 00:00:00",
          this.channel
        );
        const len = this.programRecordList.length;
        if (len > 0) {
          this.timeStart = new Date(
            this.programRecordList[len - 1].programmeItem.stop
          ).toISOString();
          this.timeStop = new Date(
            this.programRecordList[len - 1].programmeItem.stop
          ).toISOString();
        }
      } catch (error) {
        this.$toast.error("Není možné načíst televizní program!");
      }
      this.computeProgramBar();
    },
    computeProgramBar() {
      this.programBar = [];
      for (const i in this.programRecordList) {
        this.programBar.push({
          startTimeString: new Date(
            this.programRecordList[i].programmeItem.start
          ).toLocalTimeString(),
          stopTimeString: new Date(
            this.programRecordList[i].programmeItem.stop
          ).toLocalTimeString(),
          start: this.getSecondsOfDay(
            this.programRecordList[i].programmeItem.start
          ),
          stop: this.getSecondsOfDay(
            this.programRecordList[i].programmeItem.stop
          ),
          name: this.getTitleFromRecord(this.programRecordList[i].programme),
        });
      }
    },
    getSecondsOfDay(time) {
      const timeCurrent = new Date(time);

      return (new Date(time) - new Date(this.fromDate + " 00:00")) / 1000;
    },
    getTitleFromRecord(record) {
      for (const i in record.tag) {
        if (record.tag[i].tagName == "title") {
          return "" + record.tag[i].content;
        }
      }
      return "" + record.id;
    },
    showDialog(title, message, mode) {
      if (!mode) {
        mode = "info";
      }
      this.dialog = true;
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogMode = mode;
    },
    hideDialog() {
      this.dialog = false;
    },
    programFilter() {
      try {
        if (this.$refs.filterInput._data.lazySearch) {
          this.findPrograms(this.$refs.filterInput._data.lazySearch);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async findPrograms(queryText) {
      try {
        if (queryText) {
          const source = await restAdapter.getPrograms(queryText);
          this.programList = [];
          for (const i in source) {
            this.programList.push({
              name: this.getTitleFromRecord(source[i]),
              value: source[i].id,
              duration: source[i].duration,
            });
          }
        }
      } catch (error) {
        this.$toast.error("Není možné načíst seznam pořadů!");
      }
      return false;
    },
    getNameByValue(value) {
      for (const program in this.programList) {
        if (program.value == value) {
          return program.name;
        }
      }
      return "Unknown";
    },
  },
};
</script>