
export class RestApiConfig {
    public static URL = "slagr.api.ih.cz/api";
    public static PROTOCOL = "https";
    public static API_KEY_HEADER_NAME = "api_key";
    public static API_KEY = "adfsfsfsdf";
}


export default class RestApiAdapter {

    /*
    var args = {
                data: { test: "hello" }, // data passed to REST method (only useful in POST, PUT or PATCH methods)
                path: { "id": 120 }, // path substitution var
                parameters: { arg1: "hello", arg2: "world" }, // this is serialized as URL parameters
                headers: { "test-header": "client-api" } // request headers
            };
    */


    private target: string;
    private apiKey: string;
    private apiKeyName: string;
    private requestPrepare: RequestInit;


    constructor() {
        this.target = RestApiConfig.PROTOCOL + "://" + RestApiConfig.URL;
        this.apiKey = RestApiConfig.API_KEY;
        this.apiKeyName = RestApiConfig.API_KEY_HEADER_NAME;
        this.requestPrepare = {
            mode: "cors",
            headers: {
                "api_key": this.apiKey, 
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }
    }

    public async getPrograms(hint?: string) {
        return new Promise((resolve, reject) => {
            try {
                let endpoint: string =  this.target + "/program"
                const request = Object.create(this.requestPrepare);
                request.method = "GET"
                if (hint) {
                    endpoint += "/list/" + encodeURIComponent(hint)
                }                
                fetch(endpoint, request).then((res: any) => res.json())
                    .then((json: any) => {
                        if (json.code == 200) {
                            resolve(json.response)
                        } else {
                            reject(json.error)
                        }
                    }).catch(error => {
                        console.log(error)
                        reject(error)
                    });
            } catch (error) {
                console.log(error)
                reject(error)
            }
        })
    }
    public async tagEdit(tag: object) {
        return new Promise((resolve, reject) => {
            try {
                const endpoint: string =  this.target + "/tools/tag/edit"
                const request = Object.create(this.requestPrepare);
                request.method = "POST"
                request.body = JSON.stringify(tag)
                fetch(endpoint, request).then((res: any) => res.json())
                    .then((json: any) => {
                        if (json.code == 200) {
                            resolve(json.response)
                        } else {
                            reject(json.error)
                        }
                    }).catch(error => {
                        reject(error)
                    });
            } catch (error) {
                reject(error)
            }
        })
    }
    public async programEdit(id: number, duration: number) {
        return new Promise((resolve, reject) => {
            try {
                const endpoint: string =  this.target + "/tools/program/edit"
                const request = Object.create(this.requestPrepare);
                request.method = "POST"
                request.body = JSON.stringify({id: id, duration: duration})
                fetch(endpoint, request).then((res: any) => res.json())
                    .then((json: any) => {
                        if (json.code == 200) {
                            resolve(json.response)
                        } else {
                            reject(json.error)
                        }
                    }).catch(error => {
                        reject(error)
                    });
            } catch (error) {
                reject(error)
            }
        })
    }
    public async addProgram(program: object) {
        return new Promise((resolve, reject) => {
            try {
                const endpoint: string =  this.target + "/program"
                const request = Object.create(this.requestPrepare);
                request.method = "PUT"
                request.body = JSON.stringify(program)
                fetch(endpoint, request).then((res: any) => res.json())
                    .then((json: any) => {
                        if (json.code == 200) {
                            resolve(json.response)
                        } else {
                            reject(json.error)
                        }
                    }).catch(error => {
                        reject(error)
                    });
            } catch (error) {
                reject(error)
            }
        })
    }
    public async deleteProgram(id: number) {
        return new Promise((resolve, reject) => {
            try {
                const endpoint: string =  this.target + "/program/" + encodeURIComponent(id)
                const request = Object.create(this.requestPrepare);
                request.method = "DELETE"
                fetch(endpoint, request).then((res: any) => res.json())
                    .then((json: any) => {
                        if (json.code == 200) {
                            resolve(json.response)
                        } else {
                            reject(json.error)
                        }
                    }).catch(error => {
                        reject(error)
                    });
            } catch (error) {
                reject(error)
            }
        })
    }
    public async deleteProgramRecord(id: number) {
        return new Promise((resolve, reject) => {
            try {
                const endpoint: string =  this.target + "/program/record/" + encodeURIComponent(id)
                const request = Object.create(this.requestPrepare);
                request.method = "DELETE"
                fetch(endpoint, request).then((res: any) => res.json())
                    .then((json: any) => {
                        if (json.code == 200) {
                            resolve(json.response)
                        } else {
                            reject(json.error)
                        }
                    }).catch(error => {
                        reject(error)
                    });
            } catch (error) {
                reject(error)
            }
        })
    }
    public async addProgramRecord(program: object) {
        return new Promise((resolve, reject) => {
            try {
                const endpoint: string =  this.target + "/program/record"
                const request = Object.create(this.requestPrepare);
                request.method = "PUT"
                request.body = JSON.stringify(program)
                fetch(endpoint, request).then((res: any) => res.json())
                    .then((json: any) => {
                        if (json.code == 200) {
                            resolve(json.response)
                        } else {
                            reject(json.error)
                        }
                    }).catch(error => {
                        reject(error)
                    });
            } catch (error) {
                reject(error)
            }
        })
    }
    public async getProgramRecord(from: string, to: string, channel: string) {
        return new Promise((resolve, reject) => {
            try {
                const endpoint: string =  this.target + "/program/record/list"
                const request = Object.create(this.requestPrepare);
                request.method = "POST"
                request.body = JSON.stringify({channel: channel, from: new Date(from).toISOString(), to: new Date(to).toISOString()})                                
                fetch(endpoint, request).then((res: any) => res.json())
                    .then((json: any) => {
                        if (json.code == 200) {
                            resolve(json.response)
                        } else {
                            reject(json.error)
                        }
                    }).catch(error => {
                        reject(error)
                    });
            } catch (error) {
                reject(error)
            }
        })
    }
    public async removeProgramRecord(from: string, to: string, channel: string) {
        return new Promise((resolve, reject) => {
            try {
                const endpoint: string =  this.target + "/tools/record/delete"
                const request = Object.create(this.requestPrepare);
                request.method = "POST"
                request.body = JSON.stringify({channel: channel, from: new Date(from).toISOString(), to: new Date(to).toISOString()})                                
                fetch(endpoint, request).then((res: any) => res.json())
                    .then((json: any) => {
                        if (json.code == 200) {
                            resolve(json.response)
                        } else {
                            reject(json.error)
                        }
                    }).catch(error => {
                        reject(error)
                    });
            } catch (error) {
                reject(error)
            }
        })
    }    
    public async cloneProgram(from: string, to: string, targetFrom: string, channel: string) {
        return new Promise((resolve, reject) => {
            try {
                const endpoint: string =  this.target + "/tools/clone"
                const request = Object.create(this.requestPrepare);
                request.method = "POST"
                request.body = JSON.stringify({channel: channel, originDateFrom: new Date(from).toISOString(), originDateTo: new Date(to).toISOString(), targetDateFrom: new Date(targetFrom).toISOString()})                                
                fetch(endpoint, request).then((res: any) => res.json())
                    .then((json: any) => {
                        if (json.code == 200) {
                            resolve(json.response)
                        } else {
                            reject(json.error)
                        }
                    }).catch(error => {
                        reject(error)
                    });
            } catch (error) {
                reject(error)
            }
        })
    }
}

export class RestApiProtocol {

    public HTTP = "http";
    public HTTPS = "https";

}