<template>
  <v-main>
    <v-row
      justify="space-around"
      align="center"
      class="cpointer"
      @click="onClickLink(1)"
    >
      <v-col cols="12" sm="12" md="2">
        <v-icon  color="gray darken-2"> mdi-eye </v-icon>
      </v-col>
      <v-col cols="12" sm="12" md="6"> Pořady </v-col>
    </v-row>
    <v-row
      justify="space-around"
      align="center"
      class="cpointer"
      @click="onClickLink(2)"
    >
      <v-col cols="12" sm="12" md="2">
        <v-icon  color="gray darken-2"> mdi-format-list-text </v-icon>
      </v-col>
      <v-col cols="12" sm="12" md="6"> Programy </v-col>
    </v-row>
        <v-row
      justify="space-around"
      align="center"
      class="cpointer"
      @click="onClickLink(2)"
    >
      <v-col cols="12" sm="12" md="2">
        <v-icon  color="gray darken-2"> mdi-television-classic </v-icon>
      </v-col>
      <v-col cols="12" sm="12" md="6"> Kanály </v-col>
    </v-row>
  </v-main>
</template>

<script>
export default {
  name: "MenuLeft",
  props: {},
  data() {
    return {};
  },
  methods: {
    onClickLink(val) {
      this.$emit("clicked", val, "karel");
    },
  },
};
</script>
<style lang="css" scoped>
.cpointer {
  cursor: pointer;
}
</style>