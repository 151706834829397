<template>
  <v-main>
    <v-row v-for="(programItem, index) in currentProgram" v-bind:key="index">
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="programItem.title"
          :rules="rules"
          counter="35"
          hint="Krátký název pořadu. Bude se zobrazovat jako titul v seznamech"
          label="Titul pořadu"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="8">
        <v-text-field
          v-model="programItem.subtitle"
          :rules="wordsRules"
          counter="25"
          hint="Stručný popis programu, například se jmény hlavních aktérů, režií atd.."
          label="Krátký popis programu"
          :counter-value="(v) => v.trim().split(' ').length"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="9">
        <v-textarea
          v-model="programItem.desc"
          outlined
          name="input-7-4"
          label="Kompletní popis programu"
          :value="programItem.desc"
        ></v-textarea>
      </v-col>
      <v-col class="d-flex" cols="12" sm="3">
        <v-select
          :items="langCodes"
          :rules="[(v) => !!v || 'Hodnota musí být vyplněna']"
          item-text="name"
          item-value="value"
          label="Jazyková verze"
          :value="programItem.lang"
        ></v-select>
        <button v-if="index > 0" @click="dropLang(index)">odstranit</button>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2"><v-btn @click="saveProgram">Uložit</v-btn></v-col>
      <v-col cols="12" md="2">
        <v-text-field
          dense
          v-model.number="duration"
          type="number"
          hint="Délka programu v minutách"
          label="Duration"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="8" align="right"
        ><v-btn @click="addTags">Přidat jazyk</v-btn></v-col
      >
    </v-row>
  </v-main>
</template>

<script>
export default {
  name: "ProgramAdd",
  props: {},
  data() {
    return {
      duration: 0,
      currentProgram: [
        { lang: "cz", title: "", subtitle: "", desc: "", duration: 0 },
      ],
      langCodes: [
        { name: "čeština", value: "cz" },
        { name: "slovenština", value: "sk" },
        { name: "angličtina", value: "en" },
      ],
      title: "",
      rules: [(v) => v.length <= 25 || "Max 25 characters"],
      wordsRules: [(v) => v.trim().split(" ").length <= 5 || "Max 5 words"],
    };
  },
  methods: {
    addTags() {
      this.currentProgram.push({
        lang: "sk",
        title: this.currentProgram[0].title,
        subtitle: "",
        desc: "",
      });
      this.$toast(
        "Přidali jste novou sadu popisu programu. Vyplňte všechna nová pole a uložte změny."
      );
    },
    dropLang(key) {
      this.currentProgram.splice(key, 1);
      this.$toast(
        "Odebrali jste některé popisy programu. Změny se projeví až po uložení"
      );
    },
    saveProgram() {
      const tags = [];
      for (const i in this.currentProgram) {
        tags.push({
          id: 0,
          tagName: "title",
          lang: this.currentProgram[i].lang,
          content: this.currentProgram[i].title,
        });
        tags.push({
          id: 0,
          tagName: "sub-title",
          lang: this.currentProgram[i].lang,
          content: this.currentProgram[i].subtitle,
        });
        tags.push({
          id: 0,
          tagName: "desc",
          lang: this.currentProgram[i].lang,
          content: this.currentProgram[i].desc,
        });
      }
      this.$emit("saved", { id: 0, tag: tags, duration: this.duration });
      this.$toast.success("Změny byly uloženy");
    },
  },
};
</script>