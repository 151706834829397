<template>
  <v-main>
    <alert-dialog
      v-if="dialog"
      :title="dialogTitle"
      :message="dialogMessage"
      :type="dialogMode"
      :callback="hideDialog"
    ></alert-dialog>
    <confirm-dialog
      v-if="dialogShow"
      :title="dialogTitle"
      :message="dialogMessage"
      :callback="dialogCallback"
    ></confirm-dialog>
    <v-row justify="space-around">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header> Nový záznam </v-expansion-panel-header>
          <v-expansion-panel-content>
            <program-add @saved="addProgram" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-text-field
        v-model="FinderInput"
        @keyup="programFilter"
        @click:clear="programFilter"
        label="Hledat"
        :rules="rulesFilter"
        clearable
      ></v-text-field>

      <v-expansion-panels v-model="panel">
        <v-expansion-panel v-for="(program, index) in programList" :key="index">
          <v-expansion-panel-header disable-icon-rotate>
            {{ getTitleFromRecord(program) }}
            <template v-slot:actions>
              <v-icon @click.prevent="deleteProgram(program.id)">
                mdi-trash-can-outline
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <program-edit @saved="editProgram" :program="program" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-main>
</template>


<script>
import ProgramAdd from "./ProgramAdd.vue";
import RestApiAdater from "../module/restapi.ts";
import ProgramEdit from "./ProgramEdit.vue";
import ConfirmDialog from "./ConfirmDialog.vue";
import AlertDialog from "./AlertDialog.vue";

const restAdapter = new RestApiAdater();

export default {
  name: "ProgramList",
  components: {
    ProgramAdd,
    ProgramEdit,
    ConfirmDialog,
    AlertDialog,
  },
  props: {},
  data() {
    return {
      FinderInput: "",
      panel: [],
      rulesFilter: [(v) => v.length >= 3 || v.length == 0 || "Min 3 znaky"],
      programList: [],
      programListSrc: [],
      dialog: false,
      dialogAction: "none",
      dialogParams: {},
      dialogShow: false,
      dialogTitle: "Title",
      dialogMessage: "Přeji hezký den",
    };
  },
  computed: {},
  methods: {
    showDialog(title, message, mode) {
      if (!mode) {
        mode = "info";
      }
      this.dialog = true;
      this.dialogTitle = title;
      this.dialogMessage = message;
      this.dialogMode = mode;
    },
    hideDialog() {
      this.dialog = false;
    },
    dialogCallback(result) {
      this.dialogShow = false;
      if (result) {
        if (this.dialogAction == "delete-program") {
          restAdapter.deleteProgram(this.dialogParams.id);
          this.getProgramList();
          this.panel = [];
        }
      }

      this.dialogAction = "none";
      this.dialogParams = {};
      this.dialogTitle = "";
      this.dialogMessage = "";
    },
    async editProgram(programs) {
      try {
        await restAdapter.programEdit(programs.id, programs.duration);
        for (const i in programs.tag) {
          restAdapter.tagEdit(programs.tag[i]);
        }
        this.$toast.success("Změny byly uloženy");
      } catch (error) {
        this.$toast.error("Při ukládání došlo k chybě");
        console.log(error);
      }
    },
    async addProgram(program) {
      try {
        await restAdapter.addProgram(program);
        this.getProgramList();
        this.$toast.success("Nový program byl úspěšně přidán");
      } catch (error) {
        this.$toast.error("Přidání nového záznamu se nezdařilo");
      }
    },
    deleteProgram(id) {
      this.dialogShow = true;
      this.dialogAction = "delete-program";
      this.dialogParams = { id: id };
      this.dialogTitle = "Záznam o progamu";
      this.dialogMessage = "Opravdu chcete odstranit záznam?";
      this.panel = [];
    },
    getTitleFromRecord(record) {
      for (const i in record.tag) {
        if (record.tag[i].tagName == "title") {
          return "" + record.tag[i].content;
        }
      }
      return "" + record.id;
    },
    getRecordById(id) {
      for (const i in this.programListSrc) {
        if (this.programListSrc[i].id == id) {
          return i;
        }
        return i;
      }
    },
    async getProgramList() {
      try {
        this.programListSrc = await restAdapter.getPrograms();
        this.programList = this.programListSrc;
      } catch (error) {
        this.showDialog(
          "Chyba komunikace",
          "Není možné načíst seznam pořadů!",
          "error"
        );
      }
    },
    async programFilter() {
      try {
        if (this.FinderInput.length >= 3) {
          this.programList = [];
          this.programList = await restAdapter.getPrograms(
            this.FinderInput
          );
        } else {
          this.programList = this.programListSrc;
        }
      } catch (error) {
        this.showDialog(
          "Chyba komunikace",
          "Není možné načíst seznam pořadů!",
          "error"
        );
      }
    },
  },
  mounted() {
    this.getProgramList();
  },
};
</script>