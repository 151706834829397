<template>
  <v-main>
    <svg version="1.2" width="100%" height="30px" ref="canvas">
      <rect
        x="0"
        width="100%"
        height="15"
        rx="10"
        stroke="grey"
        fill-opacity="1"
        stroke-opacity="1"
        fill="transparent"
      />

      <rect
        v-for="(program, index) in programItems"
        :key="index"
        :x="ratio * program.start + 3"
        y="3"
        :width="ratio * ((program.stop - program.start) > 0?(program.stop - program.start):86400)"
        height="9"
        rx="5"
        stroke="transparent"
        fill-opacity=".3"
        stroke-opacity=".3"
        fill="black"
        @mouseover="
          showTooltip($event, 'tooltip', 'title', program.name);
          showTooltip(
            $event,
            'tooltipTime',
            'time',
            program.startTimeString + ' - ' + program.stopTimeString
          );
        "
        @mouseout="hideTooltip('tooltip'); hideTooltip('tooltipTime')"
        class="changeOpacity"
      />
    </svg>
    <div
      id="tooltip"
      class="tooltip"
      display="none"
      style="position: absolute; display: none"
    ></div>
    <div
      id="tooltipTime"
      class="tooltip"
      display="none"
      style="position: absolute; display: none"
    ></div>
  </v-main>
</template>

<script>
export default {
  name: "dayProgramBar",
  components: {},
  props: {
    programItems: {
      type: Array,
    },
  },
  data() {
    return {
      ratio: 0,
    };
  },
  mounted() {
    this.getRatio();
  },
  methods: {
    getRatio() {
      this.ratio = (this.$refs.canvas.clientWidth - 6) / 86400;
    },
    showTooltip(evt, id, type, text) {
      try {
        const tooltip = document.getElementById(id);
        let offset = 15;
        if (type == "time") {
          offset = -40;
        }
        tooltip.innerHTML = text;
        tooltip.style.display = "block";
        tooltip.style.position = "fixed";
        tooltip.style.left =
          1 * evt.originalTarget.getBoundingClientRect().x + "px";
        tooltip.style.top =
          1 * (evt.originalTarget.getBoundingClientRect().y + offset) + "px";
      } catch (error) {
        console.log(error);
      }
    },
    hideTooltip(id) {
      try {
        const tooltip = document.getElementById(id);
        tooltip.style.display = "none";
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    window.addEventListener("resize", this.getRatio);
  },
  destroyed() {
    window.removeEventListener("resize", this.getRatio);
  },
};
</script>

<style scoped>
.tooltip {
  background: black;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 5px;
  opacity: 0.7;
  color: aliceblue;
}
.changeOpacity:hover {
  fill-opacity: 1;
}
</style>
